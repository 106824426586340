<template>
  <div class="footer">
    <div class="footer-list">
      <h4>公司电话</h4>
      <img src="../assets/images/home/footer.png" alt="">
      <h5>
        15046678908
      </h5>
    </div>
    <div class="footer-list">
      <h4>公司地址</h4>
      <img src="../assets/images/home/footer.png" alt="">
      <h5>
        黑龙江省哈尔滨市道里区群力第二大道与阳
      </h5>
      <h5>明滩大桥交汇处外滩1898A2栋商服</h5>
    </div>
    <div class="footer-list">
      <h4>公司邮箱</h4>
      <img src="../assets/images/home/footer.png" alt="">
      <h5>
        koobietech@163.com
      </h5>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer{
  width: 100vw;
  height: 382px;
  background: #F6F6F6;
  display: flex;
  justify-content: space-around;
  padding-top: 105px;
  
  .footer-list{
    width: 381px;
    text-align: left;
    line-height: 1.3;
    h4{
      font-weight: 700;
      line-height: 1;
      font-size: 24px;
      color: #333333;
    }
    h5{
      // width: 360px;
      color: #686B68;
      font-size: 20px;
      font-weight: 400;
    }
  }
}
</style>